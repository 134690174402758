import { useGetPaidPayrun } from 'domains/payrun/queries'
import type { GetPaidPayrun, PayrunId } from 'domains/payrun/types'
import { generatePath, useNavigate, useParams } from 'kitchen/router'
import { ImpossibleError } from 'kitchen/utils/error'
import { assert } from 'kitchen/utils/helpers'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Status } from 'salad/components'
import * as Icons from 'salad/icons'
import { StatusLayout } from 'salad/layouts'
import { Path } from '../../constants'
import {
  PayByBankStep,
  PayByCardStep,
  PaymentMethodStep,
  SuccessResultStep,
} from './steps'

import type { BeneficiaryCompany, PaymentFlowStep, PaymentFlowValues } from './types'

interface PaymentFlowState {
  step: PaymentFlowStep
  values: PaymentFlowValues
}

interface PaymentFlowBaseProps {
  email: string
  beneficiaryCompany: BeneficiaryCompany
  payrun: GetPaidPayrun
  onBack: () => void
}

function PaymentFlowBase({
  payrun,
  email,
  beneficiaryCompany,
  onBack,
}: PaymentFlowBaseProps) {
  const [flow, setFlow] = useState<PaymentFlowState>({
    step: 'payment-method',
    values: {
      beneficiaryCompany,
      payrun,
      email,
      paymentMethod: undefined,
    },
  })

  function update(values?: Partial<PaymentFlowValues>) {
    setFlow((prev) => ({ ...prev, values: { ...prev.values, ...values } }))
  }

  function navigate(step: PaymentFlowStep, values?: Partial<PaymentFlowValues>) {
    setFlow((prev) => ({ step, values: { ...prev.values, ...values } }))
  }

  switch (flow.step) {
    case 'payment-method':
      return (
        <PaymentMethodStep
          onContinue={(values) => {
            update(values)
            switch (values.paymentMethod) {
              case 'BANK':
                return navigate('pay-by-bank')
              case 'CARD':
                return navigate('pay-by-card')
              case undefined:
                throw new Error('Missing selected payment method')
              default:
                throw new ImpossibleError(
                  'Unhandled payment method',
                  values.paymentMethod
                )
            }
          }}
          onBack={onBack}
        />
      )
    case 'pay-by-bank':
      return (
        <PayByBankStep
          values={flow.values}
          onBack={() => {
            navigate('payment-method')
          }}
          onContinue={(values) => {
            update(values)
            navigate('success')
          }}
        />
      )
    case 'pay-by-card':
      return (
        <PayByCardStep
          values={flow.values}
          onBack={() => {
            navigate('payment-method')
          }}
          onContinue={(values) => {
            update(values)
            navigate('success')
          }}
        />
      )
    case 'success':
      return <SuccessResultStep values={flow.values} />

    default:
      throw new ImpossibleError('Unhandled step', flow.step)
  }
}

interface PaymentFlowProps {
  email: string
  beneficiaryCompany: BeneficiaryCompany
}

export const PaymentFlow = ({ beneficiaryCompany, email }: PaymentFlowProps) => {
  const navigate = useNavigate()
  const { payrunId, companyAliasName } = useParams<{
    payrunId: PayrunId
    companyAliasName: string
  }>()
  assert(payrunId)
  assert(companyAliasName)

  const getPaidPayrun = useGetPaidPayrun({ payrunId }, { suspense: true })

  assert(getPaidPayrun.data)

  switch (getPaidPayrun.data.state) {
    case 'CREATED':
      return (
        <PaymentFlowBase
          email={email}
          beneficiaryCompany={beneficiaryCompany}
          payrun={getPaidPayrun.data}
          onBack={() => {
            navigate(generatePath(Path.COMPANY_ALIAS, { companyAliasName }))
          }}
        />
      )
    case 'DECLINED':
      return (
        <StatusLayout.Root>
          <StatusLayout.Content>
            <Status.Root>
              <Status.Icon>
                <Icons.S64.Failure />
              </Status.Icon>
              <Status.Title>
                <FormattedMessage
                  id="get-paid.payment.declined.title"
                  defaultMessage="Payment declined"
                />
              </Status.Title>
              <Status.Description>
                <FormattedMessage
                  id="get-paid.payment.declined.description"
                  defaultMessage="Please create a new one."
                />
              </Status.Description>
              <Status.Action
                onClick={() =>
                  navigate(generatePath(Path.COMPANY_ALIAS, { companyAliasName }))
                }
              >
                <FormattedMessage
                  id="get-paid.payment.declined.action"
                  defaultMessage="Create a new payment"
                />
              </Status.Action>
            </Status.Root>
          </StatusLayout.Content>
        </StatusLayout.Root>
      )
    case 'PAID':
    case 'PAYING':
    case 'PENDING':
      return (
        <SuccessResultStep
          values={{
            beneficiaryCompany,
            email,
            payrun: getPaidPayrun.data,
            paymentMethod: undefined,
          }}
        />
      )
  }
}
