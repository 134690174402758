import type { CardScheme } from 'api/types/cards'
import { ImpossibleError } from 'kitchen/utils/error'
import { Amex } from './amex'
import { MasterCard } from './master-card'
import { Visa } from './visa'

interface PaymentNetworkProps {
  scheme: CardScheme
  variant?: 'color' | 'grayscale'
}

export function PaymentNetworkIcon({
  scheme,
  variant = 'grayscale',
}: PaymentNetworkProps) {
  switch (scheme) {
    case 'VISA':
      return <Visa variant={variant} />
    case 'MASTER_CARD':
      return <MasterCard variant={variant} />
    case 'AMEX':
      return <Amex variant={variant} />
    case 'UNKNOWN':
      return null
    default:
      throw new ImpossibleError('Unhandled payment network', scheme)
  }
}
