import { ExternalResource } from 'kitchen/constants'
import { useMatchMedia } from 'kitchen/hooks/use-match-media'
import { cdn } from 'kitchen/utils/helpers'
import type { ReactNode } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, List, Point, Separator } from 'salad/components'
import * as Icons from 'salad/icons'
import { HStack, Image, Text, VStack } from 'salad/primitives'
import { theme } from 'salad/stitches'
import { media } from 'salad/tokens'

interface NextStepProps {
  title: ReactNode
  subtitle: ReactNode
  active?: boolean
}

const NextStep = ({ title, subtitle, active = false }: NextStepProps) => {
  return (
    <HStack gap={16}>
      <Point mt={20} active={active}>
        <Icons.S16.Check />
      </Point>
      <VStack py={12} gap={4}>
        <Text variant="paragraph-16" weight={700}>
          {title}
        </Text>
        <Text variant="paragraph-14" color={active ? 'dark-green' : 'black'}>
          {subtitle}
        </Text>
      </VStack>
    </HStack>
  )
}

export const TryApronPromo = () => {
  const { formatMessage } = useIntl()
  const isBp2 = useMatchMedia(media['bp2'])

  return (
    <VStack gap={{ '@initial': 24, '@bp2': 48 }}>
      <VStack gap={{ '@initial': 12, '@bp2': 24 }}>
        <Text variant="headline-h2">
          <FormattedMessage
            id="get-paid.payment.success.teaser.title"
            defaultMessage="Simplify your business payments."
          />
        </Text>
        <VStack gap={8} css={{ position: 'relative' }}>
          <NextStep
            active
            title={formatMessage({
              id: 'get-paid.payment.success.teaser.step-1.label',
              defaultMessage: 'Payment details',
            })}
            subtitle={formatMessage({
              id: 'get-paid.payment.success.teaser.step-1.action',
              defaultMessage: 'Populated',
            })}
          />
          <Separator
            orientation="vertical"
            size={2}
            css={{
              position: 'absolute',
              left: '11px',
              top: '48px',
              '&[aria-orientation=vertical]': {
                height: '42px',
              },
              borderRadius: theme.radii['round'],
              color: theme.colors['light-green'],
            }}
          />
          <NextStep
            active
            title={formatMessage({
              id: 'get-paid.payment.success.teaser.step-2.label',
              defaultMessage: 'Invoice',
            })}
            subtitle={formatMessage({
              id: 'get-paid.payment.success.teaser.step-2.action',
              defaultMessage: 'Successfully paid',
            })}
          />
          <Separator
            orientation="vertical"
            size={2}
            css={{
              position: 'absolute',
              left: '11px',
              top: '122px',
              '&[aria-orientation=vertical]': {
                height: '42px',
              },
              borderRadius: theme.radii['round'],
            }}
          />
          <NextStep
            title={formatMessage({
              id: 'get-paid.payment.success.teaser.step-3.label',
              defaultMessage: 'Complete your profile for full benefits',
            })}
            subtitle={
              <VStack gap={16}>
                <Text>
                  <FormattedMessage
                    id="get-paid.payment.success.teaser.step-3.action"
                    defaultMessage="Pay any bills just as easily and so much more:"
                  />
                </Text>
                <List.Root
                  css={{
                    'li:not(:first-child)': {
                      marginBlockStart: theme.space[8],
                    },
                  }}
                >
                  <List.Item>
                    <FormattedMessage
                      id="get-paid.payment.success.teaser.step-3.action.item-1"
                      defaultMessage="Bulk payments, payroll, expenses and instant recon"
                    />
                  </List.Item>
                  <List.Item>
                    <FormattedMessage
                      id="get-paid.payment.success.teaser.step-3.action.item-2"
                      defaultMessage="Low FX rates for global payments"
                    />
                  </List.Item>
                  <List.Item>
                    <FormattedMessage
                      id="get-paid.payment.success.teaser.step-3.action.item-3"
                      defaultMessage="Pay with card even if supplier doesn’t accept cards"
                    />
                  </List.Item>
                  <List.Item>
                    <Text weight={700}>
                      <FormattedMessage
                        id="get-paid.payment.success.teaser.step-3.action.item-4"
                        defaultMessage="And more! Get a 31-day free trial and try it all out"
                      />
                    </Text>
                  </List.Item>
                </List.Root>
              </VStack>
            }
          />
        </VStack>
      </VStack>

      <VStack gap={16}>
        <Button.Root
          variant="main"
          size={isBp2 ? 'large' : 'medium'}
          onClick={() => {
            window.open(ExternalResource.MAIN_APP, '_blank')
          }}
        >
          <Button.Content>
            <FormattedMessage
              id="get-paid.payment.success.teaser.cta.try-apron"
              defaultMessage="Try apron for free"
            />
          </Button.Content>
        </Button.Root>
        <Image
          css={{ height: '21px', marginInline: 'auto' }}
          src={cdn('/logos/trustpilot-5-star.png')}
          alt="5 stars on TrustPilot"
        />
      </VStack>
    </VStack>
  )
}
