const rotation = {
  left: 0,
  right: 180,
} as const

interface ChevronProps {
  variant?: keyof typeof rotation
}

export const Chevron = ({ variant = 'left' }: ChevronProps) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    style={{ transform: `rotate(${rotation[variant]}deg)` }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9393 2.93936L6.87869 16L19.9393 29.0606L22.0607 26.9393L11.1213 16L22.0607 5.06068L19.9393 2.93936Z"
      fill="currentColor"
    />
  </svg>
)
