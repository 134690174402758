import type { AccountType } from 'api/types/accounts'
import type { Beneficiary, BeneficiaryId } from 'api/types/beneficiaries'
import type { ContactType } from 'api/types/contacts'
import type { UserPermission } from 'api/types/permissions'
import type { FxLimits, FxCurrencyRate } from 'domains/fx/types'
import type { CurrencyCode, Money } from 'domains/money/types'
import { getCurrencies, getTotal } from 'domains/money/utils'
import type { PaymentFlowType, PaymentItem } from 'domains/payment/types'
import { getBeneficiaryFromBill } from 'kitchen/utils/beneficiaries'
import { groupBy } from 'kitchen/utils/data'
import { ImpossibleError } from 'kitchen/utils/error'
import { getPaymentFees } from 'kitchen/utils/fees'
import * as formats from 'kitchen/utils/formats'
import { nonNullable, isObject } from 'kitchen/utils/helpers'
import { isPayrunLine } from 'kitchen/utils/payments'
import type {
  PayrunFlowType,
  BatchItem,
  BatchGroup,
  Contributor,
  PayrunContributors,
  PayrunLine,
  Payrun,
  Approver,
} from './types'

export const isPayrun = (input: unknown): input is Payrun =>
  isObject(input) && 'id' in input && 'flowType' in input && 'reference' in input

export function removeContributorDuplicates(contributors: PayrunContributors) {
  const { approvers, payers } = contributors

  // remove last approver if they are also a payer
  function removeLastPayingApprover(approvers: Approver[]) {
    const pendingApprovers = approvers.filter(isPendingApproverGroup)
    const lastPendingApprover = pendingApprovers[pendingApprovers.length - 1]
    const firstPayer = payers[0]
    if (
      lastPendingApprover !== undefined &&
      !Array.isArray(lastPendingApprover) &&
      firstPayer &&
      lastPendingApprover.id === firstPayer.id
    ) {
      return approvers.slice(0, -1)
    }
    return approvers
  }

  return {
    payers,
    approvers: removeLastPayingApprover(approvers),
  }
}

export function checkHasContributors(contributors: PayrunContributors) {
  return contributors.approvers.length > 0 || contributors.payers.length > 0
}

export function mapApproversToIds(approvers: Approver[]) {
  return approvers.map((item) =>
    Array.isArray(item) ? item.map(({ id }) => id) : item.id
  )
}

export function getNextApprover(approvers: Approver[]) {
  return approvers.find(isPendingApproverGroup)
}

export function isPendingApproval(contributors: PayrunContributors) {
  return getNextApprover(contributors.approvers) !== undefined
}

export function getNextToAct(contributors: PayrunContributors | undefined): {
  actionRole: 'approver' | 'payer'
  contributors: Contributor[]
} | null {
  if (!nonNullable(contributors)) {
    return null
  }
  const nextApprover = getNextApprover(contributors.approvers)
  if (contributors.payers.every((payer) => payer.hasApproved)) {
    return null
  }
  if (nextApprover === undefined) {
    return {
      actionRole: 'payer',
      contributors: contributors.payers,
    }
  }
  return {
    actionRole: 'approver',
    contributors: Array.isArray(nextApprover) ? nextApprover : [nextApprover],
  }
}

export function isPendingApproverGroup(item: Approver) {
  if (Array.isArray(item)) {
    return item.every((approver) => !approver.hasApproved)
  }
  return !item.hasApproved
}

export function toBatchItem(
  bill: PaymentItem,
  beneficiaryId: BeneficiaryId | undefined
): BatchItem {
  if (isPayrunLine(bill)) {
    const result: BatchItem = {
      invoiceId: bill.invoiceId,
      amount: { amount: bill.amount, currency: bill.currencyCode },
      beneficiaryId,
      reference: bill.reference,
      payeeReference: bill.payeeReference,
    }

    return result
  }

  return {
    invoiceId: bill.id,
    amount: { amount: bill.amountDue, currency: bill.currencyCode },
    beneficiaryId,
    reference: bill.invoiceNumber,
    payeeReference: bill.invoiceNumber,
  }
}

export function getBatchGroups(
  locale: string,
  bills: PayrunLine[],
  limits: FxLimits,
  reference = ''
): BatchGroup[] {
  const groups = groupBy(bills, (bill) => {
    if (bill.contact.id === null) {
      return `${bill.contact.name}-${bill.contact.bankAccountDetails}`
    }
    return `${bill.contact.id}-${bill.currencyCode}`
  })
  return Object.keys(groups)
    .map((key): BatchGroup => {
      const groupBills = groups[key]
      const contact = groupBills[0].contact
      const currency = groupBills[0].currencyCode
      const beneficiary = getBeneficiaryFromBill(groupBills[0])

      const limit = limits[currency]
      const batchItems = groupBills.map((bill) => toBatchItem(bill, beneficiary?.id))
      const uniquePayeeReferences = [
        ...new Set(batchItems.map((item) => item.payeeReference || '')),
      ]

      return {
        key,
        limit,
        contact,
        currency,
        beneficiary,
        batchItems,
        payeeReference:
          uniquePayeeReferences.length === 1 ? uniquePayeeReferences[0] : reference,
      }
    })
    .sort((a, b) =>
      a.contact.name.localeCompare(b.contact.name, locale, {
        sensitivity: 'base',
      })
    )
}

export const getBeneficiariesFromBatchGroups = (groups: BatchGroup[]) =>
  groups.flatMap((group) => group.batchItems.map(() => group.beneficiary))

export function getBatchItemsWithFx(
  items: BatchItem[],
  currency: CurrencyCode,
  rates: FxCurrencyRate,
  hasSanitizePayeeReference: boolean
): BatchItem[] {
  const currencies = getCurrencies(items.map((item) => item.amount))
  const isSameCurrency = currencies.length === 1 && currency === currencies[0]

  return items.map((item): BatchItem => {
    const rate = rates[item.amount.currency]
    const fxRateId = !rate || isSameCurrency ? undefined : rate.id
    const reference = item.payeeReference || item.reference || undefined

    if (hasSanitizePayeeReference) {
      return {
        ...item,
        fxRateId,
        payeeReference: reference
          ? formats.payeeReference(reference, item.amount.currency)
          : undefined,
        reference: undefined,
      }
    }

    return { ...item, fxRateId, payeeReference: reference, reference: undefined }
  })
}

export function getContactTypeForPaymentFlow(type: PayrunFlowType): ContactType {
  switch (type) {
    case 'PAYMENT':
      return 'ACCOUNTING'
    case 'MANUAL':
      return 'EXPENSE'
    case 'PAYROLL':
      return 'PAYROLL'
  }
}

export function getFlowTypeLabel(flowType: PayrunFlowType) {
  switch (flowType) {
    case 'MANUAL':
      return 'Manual'
    case 'PAYMENT':
      return 'Supplier'
    case 'PAYROLL':
      return 'Payroll'
    default:
      throw new ImpossibleError('unhandled flowType', flowType)
  }
}

export function getRequiredApproverPermissions(
  flowType: PayrunFlowType
): UserPermission[] {
  switch (flowType) {
    case 'MANUAL':
      return ['MANUAL_PAYMENTS_APPROVE']
    case 'PAYMENT':
      return ['INVOICE_PAYMENTS_APPROVE']
    case 'PAYROLL':
      return ['PAYROLL_PAYMENTS_APPROVE']
    default:
      throw new ImpossibleError('unhandled flow type', flowType)
  }
}

export function getRequiredPayerPermissions(flowType: PayrunFlowType): UserPermission[] {
  switch (flowType) {
    case 'MANUAL':
      return ['MANUAL_PAYMENTS_AUTHORISE']
    case 'PAYMENT':
      return ['INVOICE_PAYMENTS_AUTHORISE']
    case 'PAYROLL':
      return ['PAYROLL_PAYMENTS_AUTHORISE']
    default:
      throw new ImpossibleError('unhandled flow type', flowType)
  }
}

export function getRequiredEditPermissions(
  flowType: PayrunFlowType | PaymentFlowType
): UserPermission[] {
  switch (flowType) {
    case 'MANUAL':
      return ['MANUAL_PAYMENTS_EDIT']
    case 'PAYMENT':
      return ['INVOICE_PAYMENTS_EDIT']
    case 'PAYROLL':
      return ['PAYROLL_PAYMENTS_EDIT']
    case 'FEE':
      return []
    default:
      throw new ImpossibleError('unhandled flow type', flowType)
  }
}

export function mapFlowTypeToPermission(
  flowType: PayrunFlowType,
  userPermissions: UserPermission[] | undefined
): { canApprove: boolean; canPay: boolean } {
  if (userPermissions === undefined) {
    return { canApprove: false, canPay: false }
  }
  const requiredApproverPermissions = getRequiredApproverPermissions(flowType)
  const requiredPayerPermissions = getRequiredPayerPermissions(flowType)

  return {
    canApprove: requiredApproverPermissions.every((requiredPermission) =>
      userPermissions.includes(requiredPermission)
    ),
    canPay: requiredPayerPermissions.every((requiredPermission) =>
      userPermissions.includes(requiredPermission)
    ),
  }
}

export const isBatchGroup = (input: unknown): input is BatchGroup =>
  isObject(input) &&
  'key' in input &&
  'limit' in input &&
  'currency' in input &&
  'contact' in input &&
  'beneficiary' in input

export const getTotalWithPaymentFees = ({
  total,
  accountType,
  grouped,
  beneficiaries = [],
}: {
  total: Money
  accountType: AccountType | null
  grouped: boolean
  beneficiaries?: Array<Beneficiary | undefined>
}) => {
  const paymentFees = accountType
    ? getPaymentFees(total, accountType, beneficiaries, grouped)
    : null

  return {
    paymentFees,
    totalWithPaymentFees: paymentFees
      ? getTotal([total, paymentFees.total], total.currency)
      : total,
  }
}
