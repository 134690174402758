import { theme } from '../../stitches'

interface AmexProps {
  variant?: 'color' | 'grayscale'
}

export const Amex = ({ variant = 'grayscale' }: AmexProps) => {
  const fill: Record<typeof variant, string> = {
    grayscale: theme.colors['grey-20'].toString(),
    color: '#006FCF',
  }

  return (
    <svg width="36" height="18" viewBox="0 0 36 18" fill="none">
      <path
        d="M29.646 16.032L28.398 14.64L27.102 16.032H24.558H19.086V9.552H16.542L19.71 2.304H22.782L23.886 4.8V2.304H27.726L28.398 4.176L29.07 2.304H31.998V1.2C31.998 0.528 31.47 0 30.798 0H5.20006C4.52806 0 4.00006 0.528 4.00006 1.2V16.8C4.00006 17.472 4.52806 18 5.20006 18H30.798C31.47 18 31.998 17.472 31.998 16.8V16.032H29.646Z"
        fill={fill[variant]}
      />
      <path
        d="M29.982 15.264H31.998L29.358 12.432L31.998 9.64801H30.03L28.35 11.472L26.718 9.64801H24.702L27.39 12.48L24.702 15.264H26.67L28.35 13.44L29.982 15.264Z"
        fill={fill[variant]}
      />
      <path
        d="M21.486 13.968V13.104H24.654V11.808H21.486V10.944H24.702V9.64801H19.95V15.264H24.702V13.968H21.486Z"
        fill={fill[variant]}
      />
      <path
        d="M30.51 8.83201H31.95V3.16801L29.646 3.21601L28.398 6.72001L27.102 3.21601H24.702V8.83201H26.238V4.89601L27.678 8.83201H29.022L30.51 4.89601V8.83201Z"
        fill={fill[variant]}
      />
      <path
        d="M22.254 3.216H20.286L17.79 8.832H19.47L19.95 7.728H22.542L23.022 8.832H24.75L22.254 3.216ZM20.478 6.432L21.246 4.608L22.014 6.432H20.478Z"
        fill={fill[variant]}
      />
      <path d="M30.606 12.288L31.998 13.824V10.752L30.606 12.288Z" fill={fill[variant]} />
    </svg>
  )
}
