import { useMutation } from '@tanstack/react-query'
import { usePayWithCard, usePayWithPlaid } from 'domains/payment/queries'
import type { PayrunId } from 'domains/payrun/types'
import type { UseMutationOptions, UUID } from 'kitchen/types'
import { ImpossibleError } from 'kitchen/utils/error'
import { assert } from 'kitchen/utils/helpers'
import { useEffect, useState } from 'react'
import { generatePath } from 'react-router'
import { CardChallenge } from 'salad/components'
import { Path } from '../../constants'

interface PayPayload {
  payrunId: PayrunId
  paymentType: 'BANK' | 'CARD'
  idempotencyKey: UUID | null
  token?: string
}

export function usePay(options: UseMutationOptions<PayPayload, void>) {
  const [element, setElement] = useState<React.ReactElement | null>(null)
  const payWithPlaid = usePayWithPlaid()
  const payWithCard = usePayWithCard()

  useEffect(() => {
    return () => {
      setElement(null)
    }
  }, [])

  const pay = useMutation<void, Error, PayPayload>(
    async ({ payrunId, paymentType, idempotencyKey, token }) => {
      switch (paymentType) {
        case 'BANK':
          return payWithPlaid.mutateAsync({
            variant: 'get-paid',
            payrunId: payrunId,
          })
        case 'CARD': {
          assert(token, 'Token is not provided')
          return payWithCard.mutateAsync(
            {
              variant: 'get-paid',
              payrunId: payrunId,
              token,
              idempotencyKey,
              challenge: {
                successRedirectUri: new URL(
                  generatePath(Path.CARD_CHALLENGE_STATUS, { status: 'success' }),
                  window.location.origin
                ),
                failureRedirectUri: new URL(
                  generatePath(Path.CARD_CHALLENGE_STATUS, { status: 'failure' }),
                  window.location.origin
                ),
              },
              onChallenge: (url) => {
                setElement(<CardChallenge title="3DS challenge" url={url} />)
              },
              onChallengeComplete: () => {
                setElement(null)
              },
            },
            {
              onError: () => setElement(null),
            }
          )
        }
        default:
          throw new ImpossibleError('Unknown payment type', paymentType)
      }
    },
    options
  )

  return [pay, element] as const
}
