import * as Sentry from '@sentry/react'
import { FormattedMessage } from 'react-intl'
import { Status } from 'salad/components'
import * as Icons from 'salad/icons'
import { StatusLayout } from 'salad/layouts'

interface ErrorBoundaryProps {
  children: React.ReactNode
  onBack?: () => void
  onRetry?: () => void
}

export function ErrorBoundary({ children, onBack, onRetry }: ErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, resetError }) => (
        <StatusLayout.Root>
          <StatusLayout.Content>
            <Status.Root>
              <Status.Icon>
                <Icons.S64.Failure />
              </Status.Icon>
              <Status.Title>
                <FormattedMessage
                  id="common.something-went-wrong"
                  defaultMessage="Something went wrong"
                />
              </Status.Title>

              {process.env.BUILD_MODE === 'development' && error instanceof Error ? (
                <Status.Description>{error.toString()}</Status.Description>
              ) : (
                <Status.Description />
              )}
            </Status.Root>
            {onBack && (
              <Status.Action
                onClick={() => {
                  resetError()
                  onBack()
                }}
              >
                <FormattedMessage id="common.go-back" defaultMessage="Go back" />
              </Status.Action>
            )}
            {onRetry && (
              <Status.Action
                onClick={() => {
                  resetError()
                  onRetry()
                }}
              >
                <FormattedMessage id="common.try-again" defaultMessage="Try again" />
              </Status.Action>
            )}
          </StatusLayout.Content>
        </StatusLayout.Root>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
