import { Spinner, Status } from 'salad/components'
import { StatusLayout } from 'salad/layouts'

export const LoadingStatus = () => {
  return (
    <StatusLayout.Root>
      <StatusLayout.Content>
        <Status.Root>
          <Status.Icon>
            <Spinner size={64} color="grey-10" />
          </Status.Icon>
        </Status.Root>
      </StatusLayout.Content>
    </StatusLayout.Root>
  )
}
