import { useQuery, useQueries, useMutation } from '@tanstack/react-query'
import type { QueryObserverOptions, UseQueryResult } from '@tanstack/react-query'
import type { EveryPlaidErrorCode } from 'api/types/plaid'
import { CacheKey, Delay, Channel } from 'kitchen/constants'
import { useFetch } from 'kitchen/context/fetch'
import { useBroadcastChannel } from 'kitchen/hooks/use-broadcast-channel'
import type { QueryHookFactory, MutationHookFactory } from 'kitchen/types'
import { publicApi } from 'kitchen/utils/api'
import { until } from 'kitchen/utils/async'
import {
  ImpossibleError,
  ManualCancellationError,
  PlaidApiError,
} from 'kitchen/utils/error'
import { useEffect, useRef, useState } from 'react'
import { usePlaidLink } from 'react-plaid-link'
import { match } from 'ts-pattern'
import type { CurrencyCode } from '../money/types'
import {
  getPaidPaymentsList,
  getPendingPaymentsList,
  getPaymentProgress,
  getPaymentFundingAccount,
  getPaymentsTopupCurrencies,
  getPaymentStats,
  createCardPayment,
  createGetPaidCardPayment,
  getCardPayment,
  createWalletPayment,
  createPlaidPayment,
  createGetPaidPlaidPayment,
} from './requests'
import type {
  PaidPayment,
  PendingPayment,
  PaymentsListPayload,
  PaymentProgressPayload,
  PaymentProgressResponse,
  PaymentFundingAccount,
  PaymentFundingAccountPayload,
  PaymentStats,
  PaymentStatsPayload,
  CardChallengeStatus,
  CreateCardPaymentPayload,
  CreateGetPaidCardPaymentPayload,
  CardPaymentResponse,
  CreatePlaidPaymentPayload,
  CreateGetPaidPlaidPaymentPayload,
  CreateWalletPaymentPayload,
  CompaniesPaymentStatsPayload,
} from './types'

export const usePendingPaymentsList: QueryHookFactory<
  PaymentsListPayload,
  PendingPayment[]
> = (payload, options) => {
  const fetch = useFetch()

  return useQuery({
    queryKey: [CacheKey.PENDING_PAYMENTS, payload.companyId, payload.payrunId],
    queryFn: ({ signal }) => getPendingPaymentsList(fetch, payload, signal),
    ...options,
  })
}

export const usePaidPaymentsList: QueryHookFactory<PaymentsListPayload, PaidPayment[]> = (
  payload,
  options
) => {
  const fetch = useFetch()

  return useQuery({
    queryKey: [CacheKey.PAID_PAYMENTS, payload.companyId, payload.payrunId],
    queryFn: ({ signal }) => getPaidPaymentsList(fetch, payload, signal),
    ...options,
  })
}

export const usePaymentProgress: QueryHookFactory<
  PaymentProgressPayload,
  PaymentProgressResponse
> = (payload, options) => {
  const fetch = useFetch()

  return useQuery({
    queryKey: [CacheKey.PAYMENT_PROGRESS, payload.paymentId],
    queryFn: ({ signal }) => getPaymentProgress(fetch, payload, signal),
    ...options,
  })
}

export const usePaymentFundingAccount: QueryHookFactory<
  PaymentFundingAccountPayload,
  PaymentFundingAccount
> = (payload, options) => {
  const fetch = useFetch()

  return useQuery({
    queryKey: [CacheKey.FUNDING_ACCOUNT, payload.currency, payload.companyId],
    queryFn: ({ signal }) => getPaymentFundingAccount(fetch, payload, signal),
    ...options,
  })
}

export const usePaymentsTopupCurrencies: QueryHookFactory<void, CurrencyCode[]> = (
  options
) => {
  const fetch = useFetch()
  return useQuery({
    queryKey: [CacheKey.PAYMENTS_TOPUP_CURRENCIES],
    queryFn: ({ signal }) => getPaymentsTopupCurrencies(fetch, signal),
    ...options,
  })
}

export const usePaymentStats: QueryHookFactory<PaymentStatsPayload, PaymentStats> = (
  payload,
  options
) => {
  const fetch = useFetch()

  return useQuery({
    queryKey: [CacheKey.PAYMENT_STATS, payload.companyId],
    queryFn: ({ signal }) => getPaymentStats(fetch, payload, signal),
    ...options,
  })
}

export const useCompaniesPaymentStats = (
  { companies }: CompaniesPaymentStatsPayload,
  options?: QueryObserverOptions
) => {
  const fetch = useFetch()

  return useQueries<[PaymentStats, ...PaymentStats[]]>({
    queries: companies.map((companyId) => ({
      queryKey: [CacheKey.PAYMENT_STATS, companyId],
      queryFn: ({ signal }) => getPaymentStats(fetch, { companyId }, signal),
      ...options,
    })),
    // queries created dynamically from a list
    // get collapsed into simple UseQueryResult[] loosing original type
  }) as UseQueryResult<PaymentStats>[]
}

export const useCardChallengeChannel = () =>
  useBroadcastChannel<CardChallengeStatus | undefined>(Channel.CARD_CHALLENGE)

interface PayWithCardPayFlowPayload extends CreateCardPaymentPayload {
  variant: 'pay'
  onChallenge: (url: string) => void
  onChallengeComplete: () => void
}

interface PayWithCardGetPaidFlowPayload extends CreateGetPaidCardPaymentPayload {
  variant: 'get-paid'
  onChallenge: (url: string) => void
  onChallengeComplete: () => void
}

type PayWithCardPayload = PayWithCardPayFlowPayload | PayWithCardGetPaidFlowPayload

export const usePayWithCard: MutationHookFactory<PayWithCardPayload, void> = (
  options
) => {
  const fetch = useFetch()
  const cardChallengeChannel = useCardChallengeChannel()

  return useMutation(async ({ onChallenge, onChallengeComplete, ...payload }) => {
    const payment = await match(payload)
      .returnType<Promise<CardPaymentResponse>>()
      .with(
        { variant: 'pay' },
        async ({ payrunId, instrumentId, challenge, idempotencyKey }) =>
          await createCardPayment(fetch, {
            payrunId,
            instrumentId,
            challenge,
            idempotencyKey,
          })
      )
      .with(
        { variant: 'get-paid' },
        async ({ payrunId, token, challenge, idempotencyKey }) =>
          await createGetPaidCardPayment(publicApi, {
            payrunId,
            token,
            challenge,
            idempotencyKey,
          })
      )
      .exhaustive()

    if (payment.state !== 'PENDING_AUTHORIZATION') {
      return
    }

    switch (payment.challenge.type) {
      case '3DS_URI': {
        onChallenge(payment.challenge.redirectUri)

        cardChallengeChannel.once((message) => {
          switch (message) {
            case 'success':
            case 'failure':
              return onChallengeComplete()
            default:
              return
          }
        })

        await until(
          () => getCardPayment(publicApi, { paymentId: payment.id }),
          (result) => result.state === 'AUTHORIZED' || result.state === 'PAID',
          Delay.POLL
        )

        onChallengeComplete()
        return
      }
      default:
        throw new ImpossibleError('Unhandled challenge type', payment.challenge.type)
    }
  }, options)
}

const PLAID_ERROR_CODES = [
  'PAYMENT_CANCELLED',
  'PAYMENT_INSUFFICIENT_FUNDS',
  'PAYMENT_INVALID_RECIPIENT',
  'PAYMENT_INVALID_REFERENCE',
  'PAYMENT_INVALID_SCHEDULE',
  'PAYMENT_REJECTED',
  'PAYMENT_SCHEME_NOT_SUPPORTED',
  'PAYMENT_FAILED',
  'PAYMENT_BLOCKED',
  'PAYMENT_CONSENT_INVALID_CONSTRAINTS',
  'PAYMENT_CONSENT_CANCELLED',
] satisfies EveryPlaidErrorCode

interface PayWithPlaidPayFlowPayload extends CreatePlaidPaymentPayload {
  variant: 'pay'
}

interface PayWithPlaidGetPaidFlowPayload extends CreateGetPaidPlaidPaymentPayload {
  variant: 'get-paid'
}

type PayWithPlaidPayload = PayWithPlaidPayFlowPayload | PayWithPlaidGetPaidFlowPayload

export const usePayWithPlaid: MutationHookFactory<PayWithPlaidPayload, void> = (
  options
) => {
  const [plaidLinkToken, setPlaidLinkToken] = useState<string | null>(null)
  const resultPromiseRef = useRef<{
    resolve: () => void
    reject: (error: unknown) => void
  }>()
  const fetch = useFetch()

  const payMutation = useMutation(async (payload: PayWithPlaidPayload) => {
    if (process.env.PLAYWRIGHT) {
      return Promise.resolve()
    }

    const payment = await match(payload)
      .with(
        { variant: 'pay' },
        async (payload) => await createPlaidPayment(fetch, payload)
      )
      .with({ variant: 'get-paid' }, (payload) =>
        createGetPaidPlaidPayment(publicApi, payload)
      )
      .exhaustive()

    setPlaidLinkToken(payment.plaidLinkToken)

    await new Promise<void>((resolve, reject) => {
      resultPromiseRef.current = { resolve, reject }
    })
  }, options)

  const { ready: isPlaidReady, open: openPlaid } = usePlaidLink({
    token: plaidLinkToken,
    onSuccess: () => {
      const promise = resultPromiseRef.current

      if (promise === undefined) {
        throw new TypeError('Plaid link promise is undefined')
      }

      promise.resolve()
    },
    onExit: (error) => {
      payMutation.reset()
      document.querySelector('#root')?.classList.remove('blurred')

      const promise = resultPromiseRef.current

      if (promise === undefined) {
        throw new TypeError('Plaid link promise is undefined')
      }

      if (error === null) {
        // User clicked on exit button inside the widget
        promise.reject(new ManualCancellationError('Cancelled', { reason: 'plaid' }))
      } else if (PLAID_ERROR_CODES.includes(error.error_code)) {
        promise.reject(new PlaidApiError(error.error_message, { code: error.error_code }))
      } else {
        promise.reject(error)
      }
    },
  })

  // https://github.com/plaid/react-plaid-link#oauth--opening-link-without-a-button-click
  useEffect(() => {
    if (isPlaidReady && plaidLinkToken) {
      openPlaid()
      document.querySelector('#root')?.classList.add('blurred')
      return () => document.querySelector('#root')?.classList.remove('blurred')
    }
  }, [isPlaidReady, plaidLinkToken, openPlaid])

  return payMutation
}

export const usePayWithWallet: MutationHookFactory<CreateWalletPaymentPayload, void> = (
  options
) => {
  const fetch = useFetch()
  return useMutation((payload) => createWalletPayment(fetch, payload), options)
}
