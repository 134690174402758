import { useHasFeatureFlag } from 'api/hooks/features'
import { PaymentMethodBase } from 'domains/account/components'
import { FormattedMessage } from 'react-intl'
import { Chip, StepHeader } from 'salad/components'
import * as Icons from 'salad/icons'
import { StepLayout } from 'salad/layouts'
import { VStack } from 'salad/primitives'
import { ApronFooter } from '../../../components'
import type { PaymentMethodStepValues } from '../types'

interface PaymentMethodStepProps {
  onContinue: (values: PaymentMethodStepValues) => void
  onBack: () => void
}

export const PaymentMethodStep = ({ onBack, onContinue }: PaymentMethodStepProps) => {
  const [hasGetPaidCardPayment] = useHasFeatureFlag(['GET_PAID_CARD_PAYMENT'])

  return (
    <StepLayout.Root css={{ gridTemplateRows: 'auto auto 1fr' }}>
      <StepLayout.Header>
        <StepHeader.Root onBack={onBack}>
          <StepHeader.Title>
            <FormattedMessage
              id="get-paid.payment.payment-method.title"
              defaultMessage="Choose your payment method"
            />
          </StepHeader.Title>
        </StepHeader.Root>
      </StepLayout.Header>
      <VStack gap={16}>
        <PaymentMethodBase.Root
          surface="card"
          onClick={() => onContinue({ paymentMethod: 'BANK' })}
        >
          <PaymentMethodBase.Start>
            <Icons.S32.Bank />
          </PaymentMethodBase.Start>
          <PaymentMethodBase.Title>
            <FormattedMessage
              id="get-paid.payment.payment-method.option.bank"
              defaultMessage="Pay by bank"
            />
          </PaymentMethodBase.Title>
        </PaymentMethodBase.Root>
        <PaymentMethodBase.Root
          surface="card"
          disabled={!hasGetPaidCardPayment}
          onClick={() => {
            if (hasGetPaidCardPayment) {
              onContinue({ paymentMethod: 'CARD' })
            }
          }}
        >
          <PaymentMethodBase.Start>
            <Icons.S32.Card />
          </PaymentMethodBase.Start>
          <PaymentMethodBase.Title>
            <FormattedMessage
              id="get-paid.payment.payment-method.option.card"
              defaultMessage="Pay by card"
            />
          </PaymentMethodBase.Title>
          <PaymentMethodBase.End>
            <Chip variant="neutral">
              {hasGetPaidCardPayment ? (
                <FormattedMessage
                  id="get-paid.payment.payment-method.option.card.fee"
                  defaultMessage="{fee}% fee"
                  values={{ fee: '2.9' }}
                />
              ) : (
                <FormattedMessage
                  id="get-paid.payment.payment-method.option.card.coming-soon"
                  defaultMessage="By Q1 2025"
                />
              )}
            </Chip>
          </PaymentMethodBase.End>
        </PaymentMethodBase.Root>
      </VStack>
      <ApronFooter />
    </StepLayout.Root>
  )
}
