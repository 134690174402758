import type { Money, CurrencyCode } from 'domains/money/types'
import * as yup from 'yup'

class MoneySchema extends yup.ObjectSchema<Money> {
  constructor() {
    super({
      amount: yup.number().defined(),
      currency: yup.string<CurrencyCode>().defined(),
    })
  }

  min(minValue: Money, message: string) {
    return this.test('min-money', message, (value) => value.amount > minValue.amount)
  }

  max(maxValue: Money, message: string) {
    return this.test('max-money', message, (value) => value.amount <= maxValue.amount)
  }

  required(message = 'Required field') {
    return super
      .required(message)
      .withMutation(() =>
        this.test('required-money', message, (value) => value.amount > 0)
      )
  }
}

/** @deprecated For `zod` use `domains/money/validations` instead */
export const money = () => new MoneySchema()
