export const Bank = () => (
  <svg width="32" height="32" viewBox="0 0 32 32">
    <g fillRule="evenodd" clipRule="evenodd" fill="currentColor">
      <path d="M6 11.2834V12H26V11.2834L16 6.70006L6 11.2834ZM16 4.5L4 10V14H28V10L16 4.5Z" />
      <path d="M29 22.5H27V21H5V22.5H3V28H29V22.5ZM25 23V24.5H27V26H5V24.5H7V23H25Z" />
      <path d="M7 12H9V22H7V12Z" />
      <path d="M7 12V22H9V12H7Z" />
      <path d="M15 12H17V22H15V12Z" />
      <path d="M15 12V22H17V12H15Z" />
      <path d="M11 12H13V22H11V12Z" />
      <path d="M11 12V22H13V12H11Z" />
      <path d="M19 12H21V22H19V12Z" />
      <path d="M19 12V22H21V12H19Z" />
      <path d="M23 12H25V22H23V12Z" />
      <path d="M23 12V22H25V12H23Z" />
    </g>
  </svg>
)
