import { useMutation } from '@tanstack/react-query'
import { Frames, type FrameCardTokenizedEvent } from 'frames-react'
import type { MutationHookFactory } from 'kitchen/types'
import type { CardValidation } from '../components/card/types'

interface TokenizeCardPayload {
  validate: (event: FrameCardTokenizedEvent) => CardValidation
}

interface TokenizeCardResponse {
  token: string
  validation: CardValidation
}

export const useTokenizeCard: MutationHookFactory<
  TokenizeCardPayload,
  TokenizeCardResponse
> = (options) => {
  return useMutation(async ({ validate }) => {
    const event = await Frames.submitCard()
    const validation = validate(event)

    if (validation !== 'OK') {
      throw new Error('Card is not supported')
    }

    return {
      token: event.token,
      validation,
    }
  }, options)
}
