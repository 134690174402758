import type { CompanyId } from 'api/types/companies'
import type { Money } from 'domains/money/types'
import type { ApiRequest } from 'kitchen/types'
import type {
  BeneficiaryCompany,
  BeneficiaryCompanyPayload,
  CompanyDisplayNameValidationPayload,
  CompanySecuritySettings,
  CompanySecuritySettingsPayload,
  GetCompanyPayrollLimitPayload,
} from './types'

export const validateCompanyDisplayName: ApiRequest<
  CompanyDisplayNameValidationPayload,
  void
> = (fetch, payload) => fetch.get('/companies/validation', { params: payload }).void()

export const getCompanySecuritySettings: ApiRequest<
  CompanyId,
  CompanySecuritySettings
> = (fetch, companyId, signal) =>
  fetch
    .get(`/companies/${companyId}/settings/security`, { signal })
    .json<CompanySecuritySettings>()

export const setCompanySecuritySettings: ApiRequest<
  CompanySecuritySettingsPayload,
  CompanySecuritySettings
> = (fetch, payload) =>
  fetch
    .patch(`/companies/${payload.companyId}/settings/security`, {
      json: {
        secondFactorEnforcement: payload.secondFactorEnforcement,
        totp: payload.totp,
      },
    })
    .json<CompanySecuritySettings>()

export const getCompanyPayrollLimit: ApiRequest<GetCompanyPayrollLimitPayload, Money> = (
  fetch,
  { companyId },
  signal
) => fetch.get(`/companies/${companyId}/payroll-limit`, { signal }).json<Money>()

export const getBeneficiaryCompany: ApiRequest<
  BeneficiaryCompanyPayload,
  BeneficiaryCompany
> = (fetch, { companyAliasName }) => {
  return fetch.get(`/getpaid/${companyAliasName}`).json<BeneficiaryCompany>()
}
