import { setRefs } from 'kitchen/utils/helpers'
import { forwardRef, useState, useRef } from 'react'
import { Input, type InputProps } from 'salad/components'
import { useMoneyFormat, useMoneyRifm, type MoneyRifmOptions } from '../../hooks'
import type { ScaledMoney } from '../../types'

export interface MoneyInputProps
  extends Omit<InputProps, 'defaultValue' | 'value' | 'placeholder'>,
    Pick<MoneyRifmOptions, 'signDisplay'> {
  value: ScaledMoney
  onValueChange: (value: ScaledMoney) => void
  placeholder?: ScaledMoney | string
}

export const MoneyInput = forwardRef<HTMLInputElement, MoneyInputProps>(
  function MoneyInputRef(
    { placeholder = '', value, onValueChange, onFocus, onBlur, signDisplay, ...rest },
    forwardedRef
  ) {
    const [isFocused, setFocused] = useState<boolean>(false)
    const inputRef = useRef<HTMLInputElement | null>(null)
    const moneyFormat = useMoneyFormat()

    const moneyRifm = useMoneyRifm({
      ref: inputRef,
      mode: isFocused ? 'edit' : 'view',
      value,
      onChange: onValueChange,
      signDisplay,
    })

    return (
      <Input
        inputMode="decimal"
        ref={setRefs(forwardedRef, inputRef)}
        placeholder={
          typeof placeholder === 'string' ? placeholder : moneyFormat.format(placeholder)
        }
        onFocus={(event) => {
          onFocus?.(event)
          if (!event.defaultPrevented && !rest.disabled && !rest.readOnly) {
            setFocused(true)
          }
        }}
        onBlur={(event) => {
          onBlur?.(event)
          if (!event.defaultPrevented) {
            setFocused(false)
          }
        }}
        {...rest}
        {...moneyRifm}
      />
    )
  }
)
