import * as Sentry from '@sentry/react'
import {
  Frames,
  CardNumber,
  ExpiryDate,
  Cvv,
  type FramesInitProps,
  type FramesLocalization,
  type FramesProps,
  type FramesStyle,
} from 'frames-react'
import { useState, useEffect } from 'react'
import { theme } from 'salad/stitches'
import { typography } from 'salad/variants'

const style: FramesStyle = {
  base: {
    fontFamily: typography['paragraph-16'].fontFamily.value,
    fontSize: typography['paragraph-16'].fontSize.value,
    lineHeight: typography['paragraph-16'].lineHeight.value,
    fontWeight: typography['paragraph-16'].fontWeight.value,
    letterSpacing: typography['paragraph-16'].letterSpacing.value,
    color: theme.colors['black'].value,
    // @ts-expect-error Invalid in types, but valid in real CSS
    '-moz-osx-font-smoothing': 'grayscale',
    '-webkit-font-smoothing': 'antialiased',
  },
  placeholder: {
    base: {
      color: theme.colors['black-alpha-40'].value,
      opacity: 1,
    },
    focus: {
      color: theme.colors['grey-20'].value,
    },
  },
}

const localization: FramesLocalization = {
  cardNumberPlaceholder: '#### #### #### ####',
  expiryMonthPlaceholder: 'MM',
  expiryYearPlaceholder: 'YY',
  cvvPlaceholder: 'CVV',
}

const config: FramesInitProps = {
  publicKey: process.env.CKO_PUBLIC_KEY,
  localization,
  style,
}

interface FramesRootProps extends Omit<FramesProps, 'config'> {
  onError: (error: Error) => void
}

function FramesRoot(props: FramesRootProps) {
  const [status, setStatus] = useState<'ready' | 'idle'>('idle')

  useEffect(() => {
    if (navigator.onLine === false) {
      props.onError(new Error('No internet connection'))
      return
    }

    if ('Frames' in window) {
      setStatus('ready')
      return
    }

    const script = document.createElement('script')
    script.src = 'https://cdn.checkout.com/js/framesv2.min.js'

    const handleLoad = () => setStatus('ready')
    const handleError = (errorEvent: ErrorEvent) => {
      Sentry.captureException(errorEvent.error, { tags: { type: 'checkout' } })
      props.onError(errorEvent.error)
    }

    script.addEventListener('load', handleLoad, false)
    script.addEventListener('error', handleError)
    document.body.appendChild(script)

    return () => script.removeEventListener('load', handleLoad)
  }, [])

  switch (status) {
    case 'ready':
      return <Frames config={config} {...props} />
    case 'idle':
      return <>{props.children}</>
  }
}

const FramesCardNumber = () => <CardNumber style={{ height: style.base?.lineHeight }} />
const FramesExpiryDate = () => <ExpiryDate style={{ height: style.base?.lineHeight }} />
const FramesCvv = () => <Cvv style={{ height: style.base?.lineHeight }} />

export {
  FramesRoot as Root,
  FramesCardNumber as CardNumber,
  FramesExpiryDate as ExpiryDate,
  FramesCvv as Cvv,
}
