export const Card = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.4549 8.19164L6.41118 12.1918C6.14325 12.2586 5.98019 12.5299 6.047 12.7979L8.95006 24.4414C9.01687 24.7094 9.28823 24.8724 9.55617 24.8056L25.5998 20.8055C25.8678 20.7387 26.0308 20.4673 25.964 20.1994L23.061 8.55582C22.9942 8.28788 22.7228 8.12483 22.4549 8.19164ZM5.92734 10.2512C4.58764 10.5852 3.77238 11.942 4.10641 13.2817L7.00947 24.9253C7.34349 26.265 8.70031 27.0802 10.04 26.7462L26.0837 22.7461C27.4234 22.412 28.2386 21.0552 27.9046 19.7155L25.0016 8.07198C24.6675 6.73228 23.3107 5.91702 21.971 6.25104L5.92734 10.2512Z"
      fill="currentColor"
    />
    <path
      d="M5.4464 14.4936L24.3763 9.77379L25.5859 14.6253L6.65601 19.345L5.4464 14.4936Z"
      fill="currentColor"
    />
  </svg>
)
