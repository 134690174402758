import * as Icons from '../../icons'
import { Grid, VStack, type GridProps, Text, type TextProps } from '../../primitives'
import { theme } from '../../stitches'
import { NavigationButton } from '../navigation-button'
import { StepBar } from '../step-bar'

export interface StepHeaderProps extends GridProps {
  onBack?: () => void
  onClose?: () => void
  children?: React.ReactNode
}

function StepHeader({ children, onBack, onClose, css, ...rest }: StepHeaderProps) {
  return (
    <Grid
      gap={{ '@initial': 16, '@bp1': 32 }}
      css={
        onBack || onClose
          ? {
              gridTemplate: `
                "start center end" auto / 48px 1fr 48px
              `,
              '@bp1': {
                width: 608,
                maxWidth: `calc(100vw - ${theme.space[24]})`,
              },
            }
          : {
              gridTemplate: '"center" auto / 1fr',
            }
      }
      {...rest}
    >
      {onBack && (
        <NavigationButton
          onClick={() => onBack()}
          title="Go back"
          css={{ gridArea: 'start' }}
        >
          <Icons.S32.Chevron variant="left" />
        </NavigationButton>
      )}
      <VStack
        css={{
          gridArea: 'center',
          textAlign: 'center',
          '[role="progressbar"]': { marginInline: 'auto' },
          ...css,
        }}
      >
        {children}
      </VStack>
      {onClose && (
        <NavigationButton
          onClick={() => onClose()}
          title="Close"
          css={{ gridArea: 'end' }}
        >
          <Icons.S32.Cross />
        </NavigationButton>
      )}
    </Grid>
  )
}

export interface StepHeaderTitleProps extends TextProps {}

function StepHeaderTitle({ css, ...rest }: StepHeaderTitleProps) {
  return (
    <Text
      as="h2"
      variant="headline-h1"
      color="black"
      css={{ paddingBlock: theme.space[4], ...css }}
      {...rest}
    />
  )
}

export interface StepHeaderSubtitleProps extends TextProps {}

function StepHeaderSubtitle({ css, ...rest }: StepHeaderSubtitleProps) {
  return (
    <Text
      as="p"
      variant="paragraph-20"
      color="grey-60"
      css={{
        '[role="progressbar"] ~ &': {
          paddingBlockStart: theme.space[32],
        },
        ...css,
      }}
      {...rest}
    />
  )
}

export interface StepHeaderAmountProps extends TextProps {}

function StepHeaderAmountContainer({ css, ...rest }: StepHeaderAmountProps) {
  return (
    <VStack
      pt={4}
      css={{ color: theme.colors['grey-60'], justifyItems: 'center', ...css }}
      {...rest}
    />
  )
}

export {
  StepHeader as Root,
  StepBar as Bar,
  StepHeaderTitle as Title,
  StepHeaderSubtitle as Subtitle,
  StepHeaderAmountContainer as AmountContainer,
}
