import type { GetPaidAccountType } from 'api/types/accounts'
import { useAccountTypeLabel } from 'domains/account/hooks'
import type { BeneficiaryCompany } from 'domains/company/types'
import { PriceTag } from 'domains/money/components'
import { useMoneyFormat } from 'domains/money/hooks'
import type { GetPaidPayrun } from 'domains/payrun/types'
import { getTotalWithPaymentFees } from 'domains/payrun/utils'
import { useDateFormat } from 'kitchen/hooks/use-date-format'
import { FormattedMessage } from 'react-intl'
import { SerratedEdge, DataGroup, Separator } from 'salad/components'
import type { GridProps } from 'salad/primitives'
import { Flex, Grid, VStack, Text } from 'salad/primitives'
import { theme } from 'salad/stitches'
import { match } from 'ts-pattern'

const PaymentSummaryWrapper = ({ children }: GridProps) => {
  return (
    <Grid
      css={{
        gridAutoRows: '1fr auto',
        filter: `drop-shadow(${theme.shadows['elevation-200']})`,
      }}
    >
      {children}
      <SerratedEdge position="bottom" />
    </Grid>
  )
}

interface PaymentSummaryCardProps {
  payrun: GetPaidPayrun
  paymentMethod: GetPaidAccountType | undefined
  beneficiaryCompany: BeneficiaryCompany
}

export const PaymentSummaryCard = ({
  payrun,
  paymentMethod,
  beneficiaryCompany,
}: PaymentSummaryCardProps) => {
  const moneyFormat = useMoneyFormat()
  const dateFormat = useDateFormat()
  const paymentMethodLabel = useAccountTypeLabel(paymentMethod)

  const { totalWithPaymentFees, paymentFees } = getTotalWithPaymentFees({
    total: payrun.amount,
    accountType: paymentMethod ?? null,
    beneficiaries: [],
    grouped: true,
  })

  return (
    <PaymentSummaryWrapper>
      <VStack gap={24} p={32} css={{ backgroundColor: theme.colors.white }}>
        <Text variant="headline-h2">
          <FormattedMessage
            id="get-paid.payment.summary.title"
            defaultMessage="Payment"
          />
        </Text>
        <Separator variant="dotted" />
        <VStack
          gap={24}
          css={{
            '[data-group]': {
              gridAutoFlow: 'column',
              justifyContent: 'space-between',
            },
          }}
        >
          <DataGroup.Root>
            <DataGroup.Label>
              <FormattedMessage
                id="get-paid.payment.summary.label.reference"
                defaultMessage="Reference"
              />
            </DataGroup.Label>
            <DataGroup.Value>{payrun.reference}</DataGroup.Value>
          </DataGroup.Root>
          <DataGroup.Root>
            <DataGroup.Label>
              <FormattedMessage
                id="get-paid.payment.summary.label.beneficiary"
                defaultMessage="Paid to"
              />
            </DataGroup.Label>
            <DataGroup.Value>{beneficiaryCompany.legalName}</DataGroup.Value>
          </DataGroup.Root>
          <DataGroup.Root>
            <DataGroup.Label>
              <FormattedMessage
                id="get-paid.payment.summary.label.amount"
                defaultMessage="Amount"
              />
            </DataGroup.Label>
            <DataGroup.Value>{moneyFormat.format(payrun.amount)}</DataGroup.Value>
          </DataGroup.Root>

          {paymentFees && paymentMethod && (
            <>
              {paymentFees.internationalTransfer.amount > 0 && (
                <DataGroup.Root>
                  <DataGroup.Label>
                    <FormattedMessage
                      id="get-paid.payment.summary.label.international-payment-fee"
                      defaultMessage="International payment fee"
                    />
                  </DataGroup.Label>
                  <DataGroup.Value>
                    {moneyFormat.format(paymentFees.internationalTransfer)}
                  </DataGroup.Value>
                </DataGroup.Root>
              )}
              {paymentFees.processing.amount > 0 && (
                <DataGroup.Root>
                  <DataGroup.Label>
                    {match(paymentMethod)
                      .with('BANK', () => (
                        <FormattedMessage
                          id="get-paid.payment.summary.label.processing-fee.bank"
                          defaultMessage="Bank payment processing fee"
                        />
                      ))
                      .with('CARD', () => (
                        <FormattedMessage
                          id="get-paid.payment.summary.label.processing-fee.card"
                          defaultMessage="Card payment processing fee"
                        />
                      ))
                      .exhaustive()}
                  </DataGroup.Label>
                  <DataGroup.Value>
                    {moneyFormat.format(paymentFees.processing)}
                  </DataGroup.Value>
                </DataGroup.Root>
              )}
            </>
          )}
        </VStack>
        <Separator variant="dotted" />

        <DataGroup.Root align="end">
          <DataGroup.Label>
            <FormattedMessage
              id="get-paid.payment.summary.label.total"
              defaultMessage="Total amount"
            />
          </DataGroup.Label>
          <DataGroup.Value>
            <PriceTag variant="medium" value={totalWithPaymentFees} />
          </DataGroup.Value>
        </DataGroup.Root>

        <Separator variant="dotted" />

        <Flex justify="between">
          {paymentMethod && (
            <DataGroup.Root>
              <DataGroup.Label>
                <FormattedMessage
                  id="get-paid.payment.summary.label.payment-method"
                  defaultMessage="Payment method"
                />
              </DataGroup.Label>
              {paymentMethodLabel}
            </DataGroup.Root>
          )}
          {payrun.paidAt && (
            <Flex align="end">
              <Text variant="paragraph-16">
                <FormattedMessage
                  id="get-paid.payment.summary.value.payment-date"
                  defaultMessage="Paid on {date}"
                  values={{ date: dateFormat.format(payrun.paidAt) }}
                />
              </Text>
            </Flex>
          )}
        </Flex>
        <SerratedEdge position="bottom" />
      </VStack>
    </PaymentSummaryWrapper>
  )
}
