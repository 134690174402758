import { useMemo } from 'react'
import type { DateTimeFormatter } from '../types'
import { getDateTimeFormatter } from '../utils/native-date'
import { useLocale } from './use-locale'

const OPTIONS = {
  short: {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  },
  numeric: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  },
} as const

export type DateFormatStyle = keyof typeof OPTIONS

export function useDateFormat(
  style: DateFormatStyle = 'short',
  timeZone: string | undefined = undefined
): DateTimeFormatter {
  const locale = useLocale()
  return useMemo(
    () => getDateTimeFormatter(locale, { ...OPTIONS[style], timeZone }),
    [locale, timeZone, style]
  )
}
