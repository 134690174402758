import { MoneyInput } from 'domains/money/components'
import { Controller, useForm, yupResolver } from 'kitchen/forms'
import * as yup from 'kitchen/validations'
import { useId } from 'react'
import {
  InputGroup,
  Surface,
  Item,
  Avatar,
  Separator,
  Button,
  PayeeReferenceInput,
  PaymentNetworkIcon,
} from 'salad/components'
import { StepLayout } from 'salad/layouts'
import { Box, Flex, Text, VStack, HStack } from 'salad/primitives'
import { theme } from 'salad/stitches'
import { ApronFooter } from '../../../components'
import type { EntryStepValues } from '../types'

const validationSchema = yup.object({
  amount: yup.money().required(),
  billReference: yup.string().required('Required'),
})

interface EntryStepProps {
  values: EntryStepValues
  onSubmit: (values: EntryStepValues) => void
  submitting: boolean
}

export function EntryStep({ values, onSubmit, submitting }: EntryStepProps) {
  const formId = useId()

  const { handleSubmit, control } = useForm<EntryStepValues>({
    defaultValues: values,
    resolver: yupResolver(validationSchema),
  })

  return (
    <StepLayout.Root size={512} css={{ gridTemplateRows: 'auto 1fr' }}>
      <VStack gap="32" as="form" id={formId} onSubmit={handleSubmit(onSubmit)}>
        <Surface variant="flat">
          <VStack>
            <Box p={24}>
              <Item.Root size="medium" css={{ minHeight: 62, pointerEvents: 'none' }}>
                <Item.Start>
                  <Avatar
                    variant="square"
                    name={values.beneficiaryCompany.legalName}
                    size="large"
                    aria-hidden
                  />
                </Item.Start>
                <Item.Content>
                  <VStack>
                    <Text variant="headline-h2">
                      {values.beneficiaryCompany.legalName}
                    </Text>
                    <Text variant="paragraph-16" color="black-alpha-60">
                      is requesting a payment
                    </Text>
                  </VStack>
                </Item.Content>
              </Item.Root>
            </Box>
            <Separator size={1} />
            <VStack p={32} gap={24}>
              <Controller
                control={control}
                name="amount"
                render={({ field, fieldState: { error } }) => (
                  <InputGroup.Root>
                    <InputGroup.Label>Amount</InputGroup.Label>
                    <MoneyInput
                      aria-label="Amount"
                      aria-invalid={error !== undefined}
                      placeholder={field.value}
                      autoComplete="off"
                      value={field.value}
                      onValueChange={(value) => {
                        field.onChange(value)
                      }}
                    />
                    {error && <InputGroup.Message>{error.message}</InputGroup.Message>}
                  </InputGroup.Root>
                )}
              />

              <Controller
                control={control}
                name="billReference"
                render={({ field, fieldState: { error } }) => (
                  <InputGroup.Root>
                    <InputGroup.Label>Invoice reference</InputGroup.Label>
                    <PayeeReferenceInput
                      {...field}
                      currency={values.amount.currency}
                      autoComplete="off"
                      aria-invalid={error !== undefined}
                    />
                    {error && <InputGroup.Message>{error.message}</InputGroup.Message>}
                  </InputGroup.Root>
                )}
              />
            </VStack>
            <Separator size={1} />
            <Flex p={32} align="center" direction="column" gap={8}>
              <Button.Root
                form={formId}
                variant="main"
                size="large"
                type="submit"
                loading={submitting}
              >
                Pay invoice
              </Button.Root>
              <HStack gap={6} css={{ justifyContent: 'center', alignItems: 'center' }}>
                <PaymentNetworkIcon scheme="VISA" variant="color" />
                <PaymentNetworkIcon scheme="MASTER_CARD" variant="color" />
                <PaymentNetworkIcon scheme="AMEX" variant="color" />
                <Text
                  css={{
                    fontFamily: theme.fonts.dmsans,
                    fontWeight: theme.fontWeights[700],
                    fontSize: '12px',
                    lineHeight: '20px',
                  }}
                >
                  Bank transfer
                </Text>
              </HStack>
            </Flex>
          </VStack>
        </Surface>
      </VStack>
      <ApronFooter />
    </StepLayout.Root>
  )
}
