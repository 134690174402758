import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type { CompanyId } from 'api/types/companies'
import { CacheKey } from 'kitchen/constants'
import { useFetch } from 'kitchen/context/fetch'
import type { MutationHookFactory, QueryHookFactory } from 'kitchen/types'
import { publicApi } from 'kitchen/utils/api'
import { immutableRequestOptions } from 'kitchen/utils/fetch'
import {
  getBeneficiaryCompany,
  getCompanySecuritySettings,
  setCompanySecuritySettings,
  validateCompanyDisplayName,
} from './requests'
import type {
  BeneficiaryCompany,
  BeneficiaryCompanyPayload,
  CompanyDisplayNameValidationPayload,
  CompanySecuritySettings,
  CompanySecuritySettingsPayload,
} from './types'

export const useValidateCompanyDisplayName: MutationHookFactory<
  CompanyDisplayNameValidationPayload,
  void
> = (options) => {
  const fetch = useFetch()

  return useMutation({
    mutationFn: (payload) => validateCompanyDisplayName(fetch, payload),
    ...options,
  })
}

export const useCompanySecuritySettings: QueryHookFactory<
  CompanyId,
  CompanySecuritySettings
> = (companyId, options) => {
  const fetch = useFetch()

  return useQuery({
    queryKey: [CacheKey.COMPANIES, companyId, CacheKey.COMPANY_SECURITY],
    queryFn: ({ signal }) => getCompanySecuritySettings(fetch, companyId, signal),
    ...options,
  })
}

export const useSetCompanySecuritySettings: MutationHookFactory<
  CompanySecuritySettingsPayload,
  CompanySecuritySettings
> = (options) => {
  const fetch = useFetch()
  const queryClient = useQueryClient()

  return useMutation({
    ...options,
    mutationFn: (payload) => setCompanySecuritySettings(fetch, payload),
    onMutate: async (payload) => {
      if (payload.secondFactorEnforcement === 'REQUIRED') {
        await queryClient.setQueryData(
          [CacheKey.COMPANIES, payload.companyId, CacheKey.COMPANY_SECURITY],
          {
            secondFactorEnforcement: payload.secondFactorEnforcement,
          } satisfies CompanySecuritySettings
        )
      }
      return options?.onMutate?.(payload)
    },
    onSuccess: async (data, payload, context) => {
      await queryClient.invalidateQueries([
        CacheKey.COMPANIES,
        payload.companyId,
        CacheKey.COMPANY_SECURITY,
      ])
      options?.onSuccess?.(data, payload, context)
    },
  })
}

export const useBeneficiaryCompany: QueryHookFactory<
  BeneficiaryCompanyPayload,
  BeneficiaryCompany
> = (payload, options) => {
  return useQuery({
    queryKey: [CacheKey.COMPANY_DETAILS, payload.companyAliasName],
    queryFn: ({ signal }) => getBeneficiaryCompany(publicApi, payload, signal),
    ...immutableRequestOptions,
    ...options,
  })
}
