import { useId } from 'react'

interface SerratedEdgeProps {
  position: 'top' | 'bottom'
}

export function SerratedEdge({ position }: SerratedEdgeProps) {
  const id = useId()

  return (
    <svg
      width="100%"
      height={10}
      style={{ transform: `rotateX(${position === 'top' ? 0 : 180}deg)` }}
    >
      <defs>
        <pattern id={id} patternUnits="userSpaceOnUse" width={17.6} height={10}>
          <path d="M17.6 10L17.6 0L8.80001 10H17.6Z" fill="white" />
          <path d="M8.80001 10H0V0L8.80001 10Z" fill="white" />
        </pattern>
      </defs>
      <rect width="100%" height="100%" fill={`url(#${id})`} />
    </svg>
  )
}
